<template>
  <div class="amendments-container">
      <!-- <h1 style="margin-left: 20px;">403</h1> -->

            
      <vs-alert active="true">
        Dieser Bereich ist nur für Portal-Admins freigegeben.
      </vs-alert>


  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: '401',

  computed: {
    users() {
      return this.$store.getters['user/getUsers']
    },

  },
});

</script>
